import React, { useRef, useContext } from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowLongDownIcon,
} from "@heroicons/react/20/solid";

import Loading from "../components/Loading";
import ComboboxCatalogo from "./ComboboxGeneric";
import ComboboxCustom from "./ComboboxCustom";
import InputCustom from "./InputCustom";
import FilterCustom from "./Filter";

import useGetAutosPlish from "../hooks/useGetAutosPublish";
import useFetchData from "../hooks/useFetchData";

import AppContext from "../context/AppContext";

import { callApiPost, saveLogError } from "../services/HttpService";

import {
  loading,
  success,
  error,
  errorCustom,
  closedAlert,
} from "../utils/alert";

const selectedItemEmpty = {
  name: `Seleccione una opción`,
  id: `NoExiste${Math.random()}`,
  nombre: `Seleccione una opción`,
  validate: "empty#",
};

const CampoRequerido = () => {
  return (
    <span className="px-2 py-2 text-sm decoration-red-700 text-red-600">
      * Campo requerido
    </span>
  );
};

const InfoNoDisponible = () => {
  return (
    <p className="px-2 py-2 text-sm decoration-teal-700 text-teal-600">
      Información no disponible.
    </p>
  );
};

export default function FormDataAuto() {
  const form = useRef();

  const {
    carToSelect,
    errorsForm,
    toErrorsForm: setErrorsForm,
    setFetchCars,
    fetchCars,
    infoTotal,
    index,
    setIndex,
  } = useContext(AppContext);

  //Llamada de todos los coches por publicar
  const { autos: dataCars } = useGetAutosPlish();
  const { data: dataCatalogos, isLoadingCat } = useFetchData();

  const getValuesBody = (values) => {
    let idTipo = "";
    let nombreTipo = "";

    if(dataCatalogos.dataDetalleCar.tipo[0]?.id ==="not found") {
      idTipo = values["tipo[id]"];
      nombreTipo = values["tipo[name]"];
    }else {
      idTipo =  dataCatalogos.dataDetalleCar.tipo[0].id ?? "";
      nombreTipo = dataCatalogos.dataDetalleCar.tipo[0].name ?? "";
    }

    return {
      idAutomovil: dataCatalogos.cart.id,

      idMarcaMaxipublica:
        dataCatalogos.dataDetalleCar.marca[0].id_brand_maxipublica ?? "",
      nombreMarcaMaxipublica: dataCatalogos.dataDetalleCar.marca[0].brand ?? "",

      idModeloMaxipublica:
        dataCatalogos.dataDetalleCar.modelo.length > 0
          ? dataCatalogos.dataDetalleCar.modelo[0].id_model_maxipublica ?? ""
          : "",
      nombreModeloMaxipublica:
        dataCatalogos.dataDetalleCar.modelo.length > 0
          ? dataCatalogos.dataDetalleCar.modelo[0].model ?? ""
          : "",

      idAnioMaxipublica:
        dataCatalogos.dataDetalleCar.anio.length > 0
          ? dataCatalogos.dataDetalleCar.anio[0].id_anio_maxipublica ?? ""
          : "",
      nombreAnioMaxipublica:
        dataCatalogos.dataDetalleCar.anio.length > 0
          ? dataCatalogos.dataDetalleCar.anio[0].anio ?? ""
          : "",

      idVersionCaranty:
        dataCatalogos.dataDetalleCar.version.length > 0
          ? dataCatalogos.dataDetalleCar.version[0].id_version_caranty ?? ""
          : "",

      nombreVersionCaranty:
        dataCatalogos.dataDetalleCar.version.length > 0
          ? dataCatalogos.dataDetalleCar.version[0].version_json ?? ""
          : "",

      km: parseFloat(values.km.replace(",", "")),

      idVersionMaxipublica: values["versionMP[id]"],
      nombreVersionMaxipublica: values["versionMP[name]"],

      idColor: values["color[id]"],
      nombreColor: values["color[name]"],

      idTipo,
      nombreTipo,

      idTransmision: dataCatalogos.dataDetalleCar.transmision[0].id ?? "",
      nombreTransmision: dataCatalogos.dataDetalleCar.transmision[0].name ?? "",

      vin: values.vin ?? "",
      precio: dataCatalogos.cart.price ?? 0,

    };
  };

  const validateForm = (data) => {
    const validate = [
      "precio",
      "km",
      "transmision[validate]",
      "tipo[validate]",
      "color[validate]",
      "versionMP[validate]",
    ];
    let errors = {};
    validate.forEach((item) => {
      if (data.hasOwnProperty(item)) {
        if (data[item] === "empty#" || data[item] === "") {
          errors = { ...errors, [item]: "Campo requerido" };
        }
      }
    });

    setErrorsForm(errors);

    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(form.current);

    const valuesInputs = Object.fromEntries(formData);

    if (validateForm(valuesInputs)) {
      let bodyPost = getValuesBody(valuesInputs);

      console.log("🚀 Publish: ", bodyPost);

      loading("Realizando publicación...");

      const response = await callApiPost(
        "v2/publicaciones/catalogo/publicar",
        bodyPost
      );
      closedAlert();

      //Se agrega este cambio de estado para la llamada al api de getAutomoviles_v2
      setFetchCars(!fetchCars);
      let errorMessage = "";
      switch (response.code) {
        case 200:
          success("Auto publicado correctamente.");
          handleNextPrevious("next");
          break;
        case 205:
          errorMessage = `<p class="text-base font-medium text-black text-justify">
            El automóvil identificado con el VIN: ${bodyPost.vin} ya se encuentra en Maxi Publica.</p>
            <p class="text-xl font-medium text-black my-4">Pero no te preocupes, te mostramos el siguiente auto...</p>`;
          handleNextPrevious("next");
          error(errorMessage);
          break;
        default:
          errorMessage = `
            <p class="text-center text-black	text-lg font-semibold">
              Hubo un error al realizar la publicación, pero no te preocupes lo estamos revisando. Puedes seleccionar otro automóvil.
            </p>
            <p class="text-justify text-slate-400 text-sm mt-3">${response.message}</p>`;

          errorCustom(errorMessage, index > 0, "Oops...").then((result) => {
            if (result.isConfirmed) {
              handleNextPrevious("previous");
            } else if (result.isDenied) {
              handleNextPrevious("next");
            }
          });
          break;
      }

      if (response.code !== 200) {
        try {
          let title = `Error al publicar id=${bodyPost.idAutomovil}`;
          let message = response.error.message ?? "Unknown error";
          let statusCode = response.code ?? "Not code";
          saveLogError({ title, message, statusCode });
        } catch (error) {
          console.log("Error Saved log", error);
        }
      }
      setErrorsForm({});
    } else {
      console.log("CAMPOS VACIOS");
    }
  };

  const handleNextPrevious = (action) => {
    let count =
      action === "next"
        ? index === parseInt(infoTotal.total) - 1
          ? 0
          : index + 1
        : index - 1;

    setIndex(count);
    carToSelect(count);
  };

  if (
    !isLoadingCat &&
    dataCatalogos.dataDetalleCar &&
    dataCatalogos.dataDetalleCar.version
  ) {
    var versionCarantyName = dataCatalogos.dataDetalleCar.version[0].version
      ? dataCatalogos.dataDetalleCar.version[0].version
      : dataCatalogos.cart.version;
  }

  if (Object.values(dataCars).length === 0) {
    return (
      <div className="flex flex-col justify-center items-center my-16 mx-auto w-80">
        <ArrowLongDownIcon
          className="w-8 h-8 animate-bounce"
          aria-hidden="true"
        />
        <p className="text-[32px] tracking-wide leading-relaxed	font-bold text-ic-primary text-center">
          Por el momento, no existen automóviles disponibles para publicar...
        </p>
      </div>
    );
  }

  return (
    <>
      {isLoadingCat ? (
        <Loading />
      ) : (
        <>
          <div className="mx-2 md:mx-0">
            <div className="py-0 md:py-5 flex justify-between items-center my-2">
              {index > 0 ? (
                <button
                  className="ic-btn-primray py-2 px-2"
                  onClick={() => {
                    handleNextPrevious("previous");
                  }}
                >
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              ) : (
                <span className="py-2 px-2" aria-hidden="true" />
              )}
              <div className="flex flex-col justify-center items-center">
                <span className="text-[18px] md:text-[24px] font-bold leading-8 text-gray-900">
                  Autos por publicar
                </span>
                <span className="text-sm font-medium text-gray-600">
                  {`Elemento ${infoTotal.indexOf} de ${infoTotal.total}`}
                </span>
              </div>
              <button
                className="ic-btn-primray py-2 px-2"
                onClick={() => {
                  handleNextPrevious("next");
                }}
              >
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>

            <FilterCustom />

            <div className="flex justify-center">
              <img
                className="max-w-sm h-60 transition-shadow ease-in-out duration-300 shadow-none hover:shadow-xl rounded-lg"
                src={dataCatalogos.cart.images[0].url}
                alt="Man looking at item at a store"
              />
            </div>
          </div>

          {dataCatalogos.dataDetalleCar &&
          dataCatalogos.dataDetalleCar.length === 0 ? (
            <>
              <div className="flex justify-center my-8">
                <div className="ml-4 text-left">
                  <p className="text-[18px] font-medium text-gray-900">
                    {`${dataCatalogos.cart.brand} ${dataCatalogos.cart.model} ${dataCatalogos.cart.year}`}
                  </p>
                  <p className="text-[18px]">
                    Precio: {dataCatalogos.cart.price}
                  </p>
                  <p className="text-[18px] text-gray-500">
                    ID: {dataCatalogos.cart.id}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center my-8 w-72 mx-auto">
                <p className="text-[24px] font-bold leading-8 text-gray-900 text-center">
                  La información obtenida es incompleta para realizar la
                  publicación de este Automóvil.
                </p>

                <button
                  className="my-4 ic-btn-primray py-2 px-2"
                  onClick={() => {
                    handleNextPrevious("next");
                  }}
                >
                  Siguiente auto{" "}
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mt-10 sm:mt-0">
                <form action="/" className="form-data-car form" ref={form}>
                  <div className="md:grid md:grid-cols-2 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 py-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Caranty
                        </h3>
                      </div>

                      <div className="shadow sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="id" className="ic-label-form">
                                ID
                              </label>
                              {!isLoadingCat && (
                                <InputCustom
                                  nameInput={"idCar"}
                                  value={dataCatalogos.cart.id}
                                  readOnly={true}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="vendedor"
                                className="ic-label-form"
                              >
                                Vendedor
                              </label>
                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.user.name ? (
                                <InputCustom
                                  nameInput={"vendedor"}
                                  value={`${
                                    dataCatalogos.dataDetalleCar.user.name ?? ""
                                  } ${
                                    dataCatalogos.dataDetalleCar.user
                                      .firstLastName ?? ""
                                  } ${
                                    dataCatalogos.dataDetalleCar.user
                                      .secondLastName ?? ""
                                  }`}
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="correo" className="ic-label-form">
                                Correo
                              </label>
                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.user.email ? (
                                <InputCustom
                                  nameInput={"correo"}
                                  value={
                                    dataCatalogos.dataDetalleCar.user.email
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="telefono"
                                className="ic-label-form"
                              >
                                Telefono
                              </label>
                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.user.phone ? (
                                <InputCustom
                                  nameInput={"telefono"}
                                  value={
                                    dataCatalogos.dataDetalleCar.user.phone
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                              <label htmlFor="marca" className="ic-label-form">
                                Marca
                              </label>

                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.marca[0].brand ? (
                                <InputCustom
                                  nameInput={"marca"}
                                  value={
                                    dataCatalogos.dataDetalleCar.marca[0].brand
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                              <label htmlFor="modelo" className="ic-label-form">
                                Modelo
                              </label>

                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.modelo[0].model ? (
                                <InputCustom
                                  nameInput={"modelo"}
                                  value={
                                    dataCatalogos.dataDetalleCar.modelo[0].model
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                              <label htmlFor="anio" className="ic-label-form">
                                Año
                              </label>

                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.anio[0].anio ? (
                                <InputCustom
                                  nameInput={"anio"}
                                  value={
                                    dataCatalogos.dataDetalleCar.anio[0].anio
                                  }
                                  readOnly={true}
                                />
                              ) : (
                                <InfoNoDisponible />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                              <label
                                htmlFor="version"
                                className="ic-label-form"
                              >
                                Versión
                              </label>

                              {!isLoadingCat && versionCarantyName ? (
                                <InputCustom
                                  nameInput={"versionCaranty"}
                                  value={versionCarantyName}
                                  readOnly={true}
                                />
                              ) : (
                                <p className="px-2 py-2 text-sm decoration-red-700 text-red-600">
                                  {" "}
                                  No existe información de la versión de
                                  Caranty..
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 md:mt-0 md:col-span-1">
                      <div className="px-4 py-4 sm:px-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Maxi Publica
                        </h3>
                      </div>

                      <div className="shadow sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="km" className="ic-label-form">
                                Precio
                              </label>
                              {errorsForm.hasOwnProperty("precio") && (
                                <CampoRequerido />
                              )}
                              {!isLoadingCat && (
                                <InputCustom
                                  nameInput={"precio"}
                                  value={dataCatalogos.cart.price}
                                  readOnly={true}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="km" className="ic-label-form">
                                Kilometraje
                              </label>
                              {errorsForm.hasOwnProperty("km") && (
                                <CampoRequerido />
                              )}
                              {!isLoadingCat && (
                                <InputCustom
                                  nameInput={"km"}
                                  value={dataCatalogos.cart.mileage}
                                  readOnly={
                                    dataCatalogos.cart.mileage ? true : false
                                  }
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="transmision"
                                className="ic-label-form"
                              >
                                Transmisión
                              </label>
                              {errorsForm.hasOwnProperty(
                                "transmision[validate]"
                              ) && <CampoRequerido />}
                              {!isLoadingCat && (
                                <InputCustom
                                  nameInput={"transmision"}
                                  value={
                                    dataCatalogos.dataDetalleCar.transmision[0]
                                      .name
                                  }
                                  readOnly={true}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="tipo" className="ic-label-form">
                                Tipo
                              </label>
                              {errorsForm.hasOwnProperty("tipo[validate]") && (
                                <CampoRequerido />
                              )}
                              {!isLoadingCat &&
                              dataCatalogos.dataDetalleCar.tipo[0]?.id ===
                                "not found" ? (
                                <ComboboxCatalogo
                                  items={dataCatalogos.catTipo}
                                  nameInput={"tipo"}
                                  selectedItem={selectedItemEmpty}
                                />
                              ) : (
                                <InputCustom
                                  nameInput={"tipo"}
                                  value={
                                    dataCatalogos.dataDetalleCar.tipo[0].name
                                  }
                                  readOnly={true}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label htmlFor="vin" className="ic-label-form">
                                VIN
                              </label>
                              {!isLoadingCat && (
                                <InputCustom
                                  nameInput={"vin"}
                                  value={dataCatalogos.cart.vin_number}
                                  readOnly={false}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                              <label htmlFor="color" className="ic-label-form">
                                Color
                              </label>
                              {errorsForm.hasOwnProperty("color[validate]") && (
                                <CampoRequerido />
                              )}
                              {!isLoadingCat && (
                                <ComboboxCatalogo
                                  items={dataCatalogos.catColores}
                                  nameInput={"color"}
                                  selectedItem={selectedItemEmpty}
                                />
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                              <label
                                htmlFor="version"
                                className="ic-label-form"
                              >
                                Versiónes MP
                              </label>
                              {errorsForm.hasOwnProperty(
                                "versionMP[validate]"
                              ) && <CampoRequerido />}
                              {!isLoadingCat &&
                                dataCatalogos.dataDetalleCar.versiones_posibles
                                  .length === 0 && (
                                  <p className="px-2 py-2 text-sm decoration-red-700 text-red-600">
                                    {" "}
                                    No existe información que coincida con las
                                    versiones de Maxi Publica.
                                  </p>
                                )}
                              {!isLoadingCat &&
                                dataCatalogos.dataDetalleCar.version.length >
                                  0 && (
                                  <ComboboxCustom
                                    items={
                                      dataCatalogos.dataDetalleCar
                                        .versiones_posibles
                                    }
                                    nameInput={"versionMP"}
                                    selectedItem={
                                      dataCatalogos.dataDetalleCar.version[0]
                                        .id_version_maxipublica
                                        ? dataCatalogos.dataDetalleCar
                                            .versiones_posibles[0]
                                        : selectedItemEmpty
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2 p-4 md:p-0">
                      <p className="text-sm	text-justify font-medium italic decoration-teal-700 text-teal-600">
                        Nota: algunos campos no son necesarios para realizar la
                        publicación, hay casos donde no existe información
                        disponible para mostrar en el formulario.
                      </p>
                    </div>

                    <div className="col-span-2 py-2 text-center">
                      <button
                        className="ic-btn-primray px-4 py-2 text-base w-64"
                        onClick={handleSubmit}
                      >
                        Publicar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
