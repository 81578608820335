/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

export default function LoginInicio() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Bienvenido</span>
          <span className="block text-ic-primary">
            Inicia sesión para continuar.
          </span>
        </h2>
      </div>

      <div className="flex flex-col justify-center my-8 mx-auto">
        <button
          className="my-12 ic-btn-primray py-2 px-2 w-60 mx-auto"
          onClick={() => handleLogin()}
        >
          <ArrowRightOnRectangleIcon className="w-5 h-5" aria-hidden="true" />
          &nbsp;&nbsp;Inicar Sesión
        </button>
        <p className="text-[24px] font-bold leading-8 text-gray-900 text-center mx-80">
          Herramienta para publicar autos en la plataforma de Maxi Publica.
        </p>

      </div>
    </>
  );
}
