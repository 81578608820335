import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";

import logoCaranty from "../assets/logos/iclogoblanco.png";

import { Disclosure, Menu, Transition } from "@headlessui/react";

import {
  Bars3Icon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon,
  QueueListIcon
} from "@heroicons/react/24/outline";

import useFetchDataAuthProfile from "../hooks/useMsalAuth";

const navigation = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ isAuthenticated }) => {
  const { instance } = useMsal();

  const { graphData, loadingProfile } = useFetchDataAuthProfile({});

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const generateAvatar = (text) => {
    return text
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
  };

  return (
    <>
      <Disclosure as="nav" className="bg-ic-secondary">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/" className="flex items-center">
                      <img
                        src={logoCaranty}
                        className="h-6 mr-3 sm:h-9"
                        alt="Caranty Logo"
                      />
                    </Link>
                  </div>

                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {!loadingProfile && (
                      <div className="flex items-center p-4">
                        <div className="ml-1 flex-auto">
                          {isAuthenticated && (
                            <>
                              <div className="font-medium text-white">
                                {graphData.displayName}
                              </div>
                              <div className="m-0 text-white">
                                {graphData.mail}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {
                      /* Profile dropdown */
                      isAuthenticated ? (
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <div className="h-12 w-12 text-2xl ic-btn-primray">
                                {!loadingProfile
                                  ? generateAvatar(graphData.displayName)
                                  : ""}
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/errorlog"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "w-full pl-4 py-2 text-sm text-gray-700 inline-flex "
                                    )}
                                  >
                                    <QueueListIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Registro de errores
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item key="logout">
                                {({ active }) => (
                                  <button
                                    onClick={handleLogout}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "w-full pl-4 py-2 text-sm text-gray-700 inline-flex "
                                    )}
                                  >
                                    <ArrowLeftOnRectangleIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Cerrar Sesión
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : (
                        <button
                          onClick={() => handleLogin()}
                          type="button"
                          className="inline-flex text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                        >
                          <ArrowRightOnRectangleIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                          &nbsp;Iniciar Sesión
                        </button>
                      )
                    }
                  </div>
                </div>

                <div className="-mr-2 flex md:hidden">
                  {
                    /* Mobile menu button */
                    isAuthenticated ? (
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    ) : (
                      <button
                        onClick={() => handleLogin()}
                        type="button"
                        className="inline-flex text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                      >
                        <ArrowRightOnRectangleIcon
                          className="w-5 h-5"
                          aria-hidden="true"
                        />
                        &nbsp;Iniciar
                      </button>
                    )
                  }
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  {!loadingProfile && (
                    <>
                      <div className="flex-shrink-0">
                        <div className="h-12 w-12 text-2xl ic-btn-primray">
                          {!loadingProfile
                            ? generateAvatar(graphData.displayName)
                            : ""}
                        </div>
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                          {" "}
                          {graphData.displayName}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {graphData.mail}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-3 space-y-1 px-2">
                  <Disclosure.Button
                    key="logout"
                    as="a"
                    onClick={handleLogout}
                    className="inline-flex rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    <ArrowLeftOnRectangleIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                    &nbsp;Cerrar Sesión
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Header;
