import { useState } from "react";

const useInitialState = () => {
  //State para el JSON de coches
  const [stateCart, setStateCart] = useState({});

  //State para controlar los errores de valiciacion del form de publicar
  const [errorsForm, setErrorsForm] = useState({});

  //State que lleva el auto selecionado
  const [stateCartSelected, setStateCartSelected] = useState({});

  //State para controlar el hook de llamada al api de getAutomoviles_v2
  const [fetchCars, setFetchCars] = useState(0);

  //State para guardar info de totales
  const [infoTotal, setInfoTotal] = useState({ total: 0, indexOf: 0 });

  //State para controlar el index del array de coches ( next and previous)
  const [index, setIndex] = useState(0);

  const addToCart = (payload) => {
    setStateCartSelected(payload[0]);
    setStateCart(payload);
    setfindIndexOf(payload, payload[0].id);
  };

  const carToSelect = (payload) => {
    setStateCartSelected(stateCart[payload]);
    setfindIndexOf(stateCart, stateCart[payload].id ?? "");
    setErrorsForm({});
  };

  const carToSelectFilter = (payload) => {
    setStateCartSelected(payload);
    setfindIndexOf(stateCart, payload.id ?? "");
    setErrorsForm({});
  };

  const setfindIndexOf = (payload, id) => {
    try {
      let indexOf = Object.values(payload).findIndex((item) => item.id === id);
      setInfoTotal({ total: payload.length, indexOf: indexOf + 1 });
      setIndex(indexOf);
    } catch (error) {
      console.log("indexOf=> ", error);
    }
  };

  const toErrorsForm = (payload) => {
    setErrorsForm(payload);
  };

  return {
    stateCartSelected,
    stateCart,
    addToCart,
    carToSelect,
    carToSelectFilter,
    errorsForm,
    toErrorsForm,
    fetchCars,
    setFetchCars,
    infoTotal,
    index,
    setIndex,
  };
};

export default useInitialState;
