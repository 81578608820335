// use-fetch-data.js
import { useEffect, useState } from "react";

// Msal imports
import { useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";

// Sample app imports
import { callMsGraph } from "./../auth/utils/MsGraphApiCall";

const useFetchDataAuthProfile = () => {
  const [graphData, setGraphData] = useState(null);
  const [loadingProfile, setLoading] = useState(true);

  const { result, error } = useMsalAuthentication(
    loginRequest
  );

  useEffect(() => {
    if (!!graphData) {
      // We already have the data, no need to call the API
      return;
    }

    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return;
    }

    if (result) {
      callMsGraph().then((response) => {
        setGraphData(response);
        setLoading(false);
      });
    }
  }, [error, result, graphData]);

  return {
    graphData,
    loadingProfile,
  };
};

export default useFetchDataAuthProfile;
