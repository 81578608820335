import { useLottie } from "lottie-react";
import icaranty from "../assets/Loader_Cars.json";

const style = {
  maxWidth: "500px",
  margin: "0 auto",
};

const Loading = () => {
  const options = {
    animationData: icaranty,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

export default Loading;
