import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";

import Header from "../components/Header";

const Layout = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <div className="min-h-full mb-36">
       <Header isAuthenticated={isAuthenticated} />
        <main
          role="main"
        >
          <div className="md:container md:mx-auto">{children}</div>
        </main>
      </div>
    </>
  );
};

export default Layout;
