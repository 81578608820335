import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./containers/Layout";
import NotFound from "./pages/NotFound";
import Home from './pages/Home';
import ErrorLog from './pages/ErrorLogCar';
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./auth/utils/NavigationClient";

import AppContext from "./context/AppContext";
import useInitialState from './hooks/useInitialState';
import { loginRequest } from "./auth/authConfig";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/errorlog" element={<ErrorLog loginRequest={loginRequest}/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const App = ({ pca }) => {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <ClientSideNavigation pca={pca}>
        <MsalProvider instance={pca}>
          <Layout>
            <Pages />
          </Layout>
        </MsalProvider>
      </ClientSideNavigation>
    </AppContext.Provider>
  );
};

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
const ClientSideNavigation = ({ pca, children }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

export default App;
