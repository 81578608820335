import React, { useEffect, useState } from "react";
import { fetchDataGet } from "../services/HttpService";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import Loading from "../components/Loading";
import ErrorLogin from "../components/ErrorLogin";

const ErrorLogContent = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setisLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = (event) => {
    let search = event.target.value;

    let pattern = new RegExp(`${search}.*`, "i");

    if (search) {
      let filtered = data.filter(
        (item) =>
          pattern.test(item.title) ||
          pattern.test(item.message) ||
          pattern.test(item.fecha_registro) ||
          pattern.test(item.statusCode)
      );
      setSearch(filtered);
    } else {
      setSearch(data);
    }
  };

  async function getData() {
    setisLoading(true);
    const dataLog = await fetchDataGet("error/logs/maxipublica", {});
    setData(dataLog.data);
    setSearch(dataLog.data);
    setisLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col mt-4">
            <p className="text-[24px] font-bold leading-8 text-gray-900 text-center mb-8">
              Registro de Errores
            </p>
          </div>

          <div className="mb-4 w-full md:z-auto">
            <div className="relative">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
              </div>
              <input
                type="text"
                id="default-search"
                className="block p-4 pl-10 w-full text-sm ic-input-form"
                placeholder="Buscar un elemento."
                onChange={handleSearch}
              />
            </div>
          </div>

          <div className="overflow-x-auto relative border rounded-lg">
            <table className="w-full divide-y table-auto divide-gray-200 text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-2 text-xs font-bold text-left text-gray-500 uppercase"
                  >
                    Título
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-xs font-bold text-left text-gray-500 uppercase"
                  >
                    Mensaje de Error
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-xs font-bold text-left text-gray-500 uppercase"
                  >
                    Código de Error
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-xs font-bold text-left text-gray-500 uppercase"
                  >
                    Fecha de Registro
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {search.map((log, i) => (
                  <tr key={i}>
                    <td className="px-2 py-2 text-sm text-gray-800">
                      {log.title}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800">
                      {log.message}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800">
                      {log.statusCode}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-800">
                      {log.fecha_registro}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default function ErrorLog({ loginRequest }) {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorLogin}
      loadingComponent={Loading}
    >
      <ErrorLogContent />
    </MsalAuthenticationTemplate>
  );
}
