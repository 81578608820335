// use-fetch-data.js
import { useEffect, useState, useContext } from "react";
import AppContext from "../context/AppContext";

import {
  fetchDataGet,
  callApiPost,
  saveLogError,
} from "../services/HttpService";

const useFetchData = () => {
  const { stateCartSelected } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [isLoadingCat, setisLoadingCat] = useState(true);

  useEffect(() => {
    getData(stateCartSelected);
  }, [stateCartSelected]);

  async function getData(cart) {
    setisLoadingCat(true);

    let catColores = [];
    let catTipo = [];
    let catTransmision = [];
    let dataDetalleCar = [];

    if (cart && JSON.stringify(cart) != '{}' ) {

      catColores = await fetchDataGet("publicaciones/catalogo/colores");
      catColores = catColores.code === 200 ? catColores.data : [];

      catTipo = await fetchDataGet("publicaciones/catalogo/tipo");
      catTipo = catTipo.code === 200 ? catTipo.data : [];

      catTransmision = await fetchDataGet("publicaciones/catalogo/transmicion");
      catTransmision = catTransmision.code === 200 ? catTransmision.data : [];

      if (cart.id !== "") {
        dataDetalleCar = await callApiPost(`v2/publicaciones/catalogo/detalle`, {
          idAutomovil: cart.id,
        });

        dataDetalleCar =
          dataDetalleCar.code === 200 ? dataDetalleCar.result : [];
      }

      console.log(" dataDetalleCar=> ", dataDetalleCar);

      try {
        let title = "";
        let message = "";
        let statusCode = "";

        if (dataDetalleCar.length === 0) {
          title = `No existe detalle de Auto id=${cart.id}`;
          message = dataDetalleCar.message ?? "Unknown error";
          statusCode = dataDetalleCar.code ?? "Not code";

          saveLogError({ title, message, statusCode });
        } else {
          if (!dataDetalleCar.marca || dataDetalleCar.marca.length === 0) {
            title = `No existe marca id=${cart.id}`;
            message = dataDetalleCar.message ?? "Unknown error";
            statusCode = dataDetalleCar.code ?? "Not code";

            dataDetalleCar["marca"] = [
              {
                brand: "",
                id_brand_maxipublica: "",
                id_brand_caranty: "",
              },
            ];

            saveLogError({ title, message, statusCode });
          }

          if (!dataDetalleCar.modelo || dataDetalleCar.modelo.length === 0) {
            title = `No existe modelo id=${cart.id}`;
            message = dataDetalleCar.message ?? "Unknown error";
            statusCode = dataDetalleCar.code ?? "Not code";

            dataDetalleCar["modelo"] = [
              {
                model: "",
                id_model_maxipublica: "",
                id_model_caranty: "",
              },
            ];

            saveLogError({ title, message, statusCode });
          }

          if (!dataDetalleCar.anio || dataDetalleCar.anio.length === 0) {
            title = `No existe anio id=${cart.id}`;
            message = dataDetalleCar.message ?? "Unknown error";
            statusCode = dataDetalleCar.code ?? "Not code";

            dataDetalleCar["anio"] = [
              {
                anio: "",
                id_anio_maxipublica: "",
                id_anio_caranty: "",
              },
            ];

            saveLogError({ title, message, statusCode });
          }

          if (!dataDetalleCar.version || dataDetalleCar.version.length === 0) {
            title = `No existe version id=${cart.id}`;
            message = dataDetalleCar.message ?? "Unknown error";
            statusCode = dataDetalleCar.code ?? "Not code";

            dataDetalleCar["version"] = [
              {
                version: "",
                id_version_maxipublica: "",
                id_version_caranty: "",
                version_json: "",
              },
            ];

            saveLogError({ title, message, statusCode });
          }

          if (
            !dataDetalleCar.versiones_posibles ||
            dataDetalleCar.versiones_posibles.length === 0
          ) {
            title = `No existen versiones_posibles id=${cart.id}`;
            message = dataDetalleCar.message ?? "Unknown error";
            statusCode = dataDetalleCar.code ?? "Not code";

            dataDetalleCar["versiones_posibles"] = [];
            saveLogError({ title, message, statusCode });
          }
        }
      } catch (error) {
        console.log("detalle catch", error);
        saveLogError({ title: "catch", message: error, statusCode: "NotCode" });
      }

      setisLoadingCat(false);
    } else {
      setisLoadingCat(true);
    }

    setData({
      cart,
      catColores,
      catTipo,
      catTransmision,
      dataDetalleCar,
    });
  }

  return { data, isLoadingCat };
};

export default useFetchData;
