import { Fragment, useState, useContext } from "react";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import AppContext from "../context/AppContext";

const FilterCustom = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [itemsFilter, setItemsFilter] = useState([]);

  const { carToSelectFilter, stateCart } = useContext(AppContext);

  const handleChange = (event) => {
    const valueSearch = event.target.value;
    let filtered = [];
    if (valueSearch) {
      let pattern = new RegExp(`${valueSearch}.*`, "i");

      filtered = stateCart.filter(
        (item) => pattern.test(item.id) || pattern.test(item.brand) || pattern.test(item.vin_number) || pattern.test(item.price)
      );
      setItemsFilter(filtered);
    }
    setIsShowing(filtered.length > 0);
  };

  const handleSelectedToCar = (car) => {
    carToSelectFilter(car);
    setIsShowing(false);
  };

  return (
    <>
      <div className="my-4 w-full md:z-auto">
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
          </div>
          <input
            type="text"
            id="default-search"
            className="block p-4 pl-10 w-full text-sm ic-input-form"
            placeholder="Busqueda por ID, Modelo, Precio ó VIN."
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="w-full">
        <Popover className="relative">
          <Transition
            show={isShowing}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute inset-x-0 shadow-xl bg-white w-full md:w-11/12 mx-auto -mt-1 rounded-lg rounded-t-none">
              <div className="flex justify-end static bg-white rounded-t-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <XMarkIcon
                  className="mr-2 mt-2 w-6 h-6 cursor-pointer hover:bg-gray-100"
                  onClick={() => setIsShowing(false)}
                  aria-hidden="true"
                />
              </div>
              <div className="overflow-y-auto	rounded-b-lg shadow-lg ring-black  w-full max-h-96">
                <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-3">
                  {itemsFilter.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => {
                        handleSelectedToCar(item);
                      }}
                      className="-m-3 flex items-center rounded-lg p-2 transition duration-150
                                ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring
                                focus-visible:ring-opacity-50"
                    >
                      <div className="ml-4 text-left">
                        <p className="text-[13px] font-medium text-gray-900">
                          {`${item.brand} ${item.model} ${item.year}`}
                        </p>
                        <p className="text-[13px] text-gray-500">Precio: {item.price}</p>
                        <p className="text-[13px] text-gray-500">ID: {item.id}</p>
                        <p className="text-[13px] text-gray-500">VIN: {item.vin_number}</p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </>
  );
};

export default FilterCustom;
