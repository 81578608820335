import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import FormDataAuto from "../components/FormDataAuto";
import LoginInicio from "./LoginInicio";

const Home = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <FormDataAuto />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginInicio />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
