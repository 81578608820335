import axios from "axios";

const API = process.env.REACT_APP_API;
const headers = JSON.parse(process.env.REACT_APP_API_HEADERS);

export async function fetchDataGet(urlGet, query = {}) {
  const queryValues = { backend: "azure",...query };
  try {
    const { data: response } = await axios({
      method: "get",
      url: `${API}/${urlGet}`,
      headers,
      params: queryValues,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
  return [];
}

export async function callApiPost(urlPost, body, query = {}) {
  const queryValues = { backend: "azure",...query };

  try {
    const { data: response } = await axios({
      method: "post",
      url: `${API}/${urlPost}`,
      data: body,
      headers,
      params: queryValues,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
  return [];
}

export async function saveLogError(messageError) {
  await callApiPost(
    "error/logs/maxipublica",
    messageError
  );
}
