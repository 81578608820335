import { useEffect, useState, useContext } from "react";
import axios from "axios";
import AppContext from "../context/AppContext";

const API = process.env.REACT_APP_API;

const useGetAutosPlish = () => {
  const { addToCart, fetchCars } = useContext(AppContext);

  const [autos, setAutos] = useState([]);

  useEffect(() => {
    fetchData();
  }, [fetchCars]);

  async function fetchData() {
    const headers = JSON.parse(process.env.REACT_APP_API_HEADERS);
    const url = `${API}/getAutomoviles_v2`;
    const params = { backend: "azure" };
    try {
      const { data: response } = await axios({
        method: "get",
        url,
        headers,
        params,
      });

      if(response.code === 200) {
        addToCart(response.result);
        setAutos(response.result);
      }
    } catch (error) {}
  }

  return { autos };
};

export default useGetAutosPlish;
