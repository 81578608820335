import React, { useState } from "react";

const InputCustom = ({ value, nameInput, readOnly = true }) => {
  const [valueInput, setValue] = useState(value);
  return (
    <input
      type="text"
      name={nameInput}
      onChange={(e) => setValue(e.target.value)}
      className="ic-input-form "
      value={valueInput || ""}
      readOnly={readOnly}
    />
  );
};

export default InputCustom;
