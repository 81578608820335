import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const loading = (message) => {
  MySwal.fire({
    title: `<p>${message}</p>`,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      MySwal.showLoading();
    },
  });
};

export const success = (message) => {
  MySwal.fire({
    icon: "success",
    title: message,
    showCloseButton: true,
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
    buttonsStyling: false,
    customClass: {
      confirmButton: "ic-btn-primray px-4 py-2 text-base w-48",
    },
    timer: 20000,
  });
};

export const error = (message, title = "", icon="warning") => {
  MySwal.fire({
    icon: icon,
    title: title,
    html: `<div class="mx-auto space-x-4"> ${message}</div>`,
    showCloseButton: true,
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
    buttonsStyling: false,
    customClass: {
      confirmButton: "ic-btn-primray px-4 py-2 text-base w-48",
    }
  });
};

export const errorCustom = (message, isPrevious, title = "") => {
  const previous = isPrevious
    ? {
        confirmButtonText:`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                          </svg>`,
      }
    : {
      showConfirmButton: false,
    };

  return Swal.fire({
    title: title,
    icon: "warning",
    html: `<div class="mx-auto space-x-4"> ${message}</div>`,
    showCloseButton: true,
    ...previous,
    showDenyButton: true,
    denyButtonText: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "ic-btn-primray py-2 px-2 mx-4",
      denyButton: "ic-btn-primray py-2 px-2 mx-4",
    },
  });
};

export const closedAlert = () => {
  MySwal.close({});
};
